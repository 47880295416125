@tailwind base;

@layer base {
  html {
    @apply text-sparkd-black;
  }
}

@tailwind components;

/**
    apply default styling for InputField
    reason for using this is not being able to override tailwind classes
    because of how the browser applies css rules

 */

.input-field {
  @apply border border-gray-300 text-gray-900 text-base rounded-lg placeholder:text-gray-400 focus:ring-1 focus:ring-cinchio-blue-500 focus-visible:outline-0 focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-100 block w-full py-2.5 px-3;
}

/** according tailwinds github, setting a base font-size here reflects across all classes */
html {
  font-size: 1rem;
}

@tailwind utilities;
